import { useEffect, useState, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AlertContext } from "@cargo/ui-kit/alert/alert";

// Referenced from https://github.com/remix-run/history/blob/dev/docs/blocking-transitions.md 
export const useNavigationBlocker = ( shouldBlock, allowedRoutes, confirmCallback ) => {
	const history = useHistory();
	const [nextLocation, setNextLocation] = useState(null);
	const { openModal } = useContext(AlertContext);
	const shouldBlockRef = useRef(shouldBlock);

	useEffect(() => {
		shouldBlockRef.current = shouldBlock;
	  }, [shouldBlock]);

	useEffect(() => {
		const unblock = history.block((tx) => {
			// Use the latest shouldBlock value
			if (!shouldBlockRef.current) return true;

			if( allowedRoutes.indexOf( tx.pathname ) !== -1 ) return true;

			openModal({
				header: 'You have unpublished changes.',
				type: 'confirm',
				confirmButton: 'Discard',
				denyButton: 'Cancel',
				onConfirm: () => {
					shouldBlockRef.current = false;
					unblock(); // Unblock to allow navigation
					confirmCallback && confirmCallback();
					history.push(tx.pathname); // Manually push the captured location
				},
				onDeny: () => {
					setNextLocation(null); // Clear the location if user cancels
				},
			});
			// Return false to prevent navigation until user confirms
			return false;
		});

		return () => {
			unblock();
		};
	}, [history, openModal, allowedRoutes, confirmCallback]);

	return nextLocation;
};